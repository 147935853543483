var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { httpRequest } from '../../utils/httpRequest';
import { useScsUrlParts } from './useScsUrlParts';
import { useVehicle } from './useVehicle';
import { useSvd } from './useSvd';
import { useI18n } from '@oneaudi/i18n-context';
import { messages } from '../../i18n/messages';
export const useChainDealers = (vehicleId) => {
    const { svd, svdError } = useSvd();
    const [error, setError] = useState(svdError);
    const { vehicle, error: vehicleError } = useVehicle(vehicleId);
    const [dealers, setDealers] = useState([]);
    const [finished, setFinished] = useState(false);
    const urlParts = useScsUrlParts();
    const dealersHeadline = useI18n({
        defaultMessage: messages.dealerChain.headline.defaultMessage,
        id: messages.dealerChain.headline.id,
    });
    useEffect(() => {
        setError(vehicleError);
        if (vehicleError) {
            setFinished(true);
        }
    }, [vehicleError]);
    useEffect(() => {
        var _a;
        if (vehicle && !error) {
            const validRequest = svd && urlParts && vehicle.investorSharedVehicle && vehicle.dealer.dealerContextLinkData;
            if (!validRequest) {
                setFinished(true);
                return;
            }
            const scsUrl = `${urlParts.scsBaseUrl}${urlParts.versionsMapping.version}/search/dealers/${urlParts.language}/${urlParts.market}?svd=${svd}`;
            const fetchDealers = (url, options) => __awaiter(void 0, void 0, void 0, function* () {
                try {
                    const response = yield httpRequest(url, options);
                    setDealers(response.parsedBody.results);
                    setFinished(true);
                }
                catch (err) {
                    setError(err);
                    setFinished(true);
                }
            });
            fetchDealers(scsUrl, {
                method: 'POST',
                body: JSON.stringify((_a = vehicle === null || vehicle === void 0 ? void 0 : vehicle.dealer) === null || _a === void 0 ? void 0 : _a.dealerContextLinkData),
            });
        }
    }, [svd, vehicle, error]);
    return {
        dealers,
        error,
        dealersHeadline,
        finished,
    };
};

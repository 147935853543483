export const messages = {
    exampleComponent: {
        headline: {
            defaultMessage: 'i18n ;-): ',
            id: 'sc.example.component.headline',
        },
    },
    consumptionTileElement: {
        efficiency: {
            id: 'nemo.ui.sc.result.eeclink',
            defaultMessage: 'CO<sub>2</sub> efficiency',
        },
        electricalRange: {
            id: 'nemo.ui.sc.tiles.electrical.range',
            defaultMessage: 'range',
        },
        consumptionLink: {
            id: 'nemo.ui.sc.tiles.consumption.link.text',
            defaultMessage: 'consumption data',
        },
        consumptionDisclaimer: {
            id: 'nemo.ui.sc.tiles.consumption.disclaimer',
            defaultMessage: 'Only WLTP values are available for this vehicle',
        },
        consumptionCombined: {
            id: 'nemo.ui.sc.result.consumptionDisplay',
            defaultMessage: 'Consumption combined',
        },
        emissionCombined: {
            id: 'nemo.ui.sc.result.emissionDisplay',
            defaultMessage: 'Emission combined',
        },
        electricalRangeCombined: {
            id: 'nemo.ui.sc.result.electricalRange',
            defaultMessage: 'Electrical-range combined',
        },
    },
    dealerFinancing: {
        currencyUnit: {
            defaultMessage: '€',
            id: 'sc.fixed.dealer.financing.currency.unit',
        },
        templates: {
            LS: {
                defaultMessage: '{productName}, Laufzeit {duration} Monate, jährl. Fahrleistung[additionalFootnote] {mileage} km, Sonderzahlung {downPayment}€. {disclaimInfo1}',
                id: '',
            },
        },
        monthlyInstalments: {
            defaultMessage: 'monatliche Leasingraten (inkl. MwSt.) à',
            id: 'sc.fixed.dealer.financing.monthly.instalments',
        },
        modal: {
            headline: {
                defaultMessage: 'Beispielhafte Leasingkonditionen',
                id: 'sc.fixed.dealer.financing.headline',
            },
            subHeadline: {
                defaultMessage: 'Dies ist ein Leasingbeispiel. Gerne passt Ihr Audi Partner dieses gemeinsam mit Ihnen an Ihre Bedürfnisse an.',
                id: 'sc.fixed.dealer.financing.subheadline',
            },
        },
        disclaimers: {
            ls: {
                defaultMessage: 'Ein Angebot der Audi Leasing, Zweigniederlassung der Volkswagen Leasing GmbH, Gifhorner Str. 57, 38112 Braunschweig, für Privatkunden. Bonität vorausgesetzt. Es besteht ein Widerrufsrecht für Verbraucher im Fernabsatz.',
                id: 'sc.fixed.dealer.financing.leasing.disclaimer',
            },
            vc: {
                defaultMessage: 'KSB Disclaimer',
                id: 'sc.fixed.dealer.financing.ksb.disclaimer',
            },
        },
        netcreditamount: {
            label: {
                defaultMessage: 'Nettodarlehensbetrag',
                id: 'sc.fixed.dealer.financing.netCreditAmount.label',
            },
            unit: {
                defaultMessage: '€',
                id: 'sc.fixed.dealer.financing.currency.unit',
            },
        },
        duration: {
            label: {
                defaultMessage: 'Laufzeit',
                id: 'sc.fixed.dealer.financing.duration.label',
            },
            unit: {
                defaultMessage: 'Monate',
                id: 'sc.fixed.dealer.financing.duration.unit',
            },
        },
        mileage: {
            label: {
                defaultMessage: 'Jährliche Fahrleistung',
                id: 'sc.fixed.dealer.financing.mileage.label',
            },
            unit: {
                defaultMessage: 'km',
                id: 'sc.fixed.dealer.financing.mileage.unit',
            },
        },
        downpaymentamount: {
            label: {
                defaultMessage: 'Sonderzahlung',
                id: 'sc.fixed.dealer.financing.downPaymentAmount.label',
            },
            unit: {
                defaultMessage: 'Euro',
                id: 'sc.fixed.dealer.financing.currency.unit',
            },
        },
        downpayment: {
            label: {
                defaultMessage: 'Anzahlung',
                id: 'sc.fixed.dealer.financing.downPayment.label',
            },
            unit: {
                defaultMessage: 'Euro',
                id: 'sc.fixed.dealer.financing.currency.unit',
            },
        },
        finalrate: {
            label: {
                defaultMessage: 'Abschlussrate',
                id: 'sc.fixed.dealer.financing.finalRate.label',
            },
            unit: {
                defaultMessage: 'Euro',
                id: 'sc.fixed.dealer.financing.currency.unit',
            },
        },
        painterestrate: {
            label: {
                defaultMessage: 'Sollzinssatz (gebunden) p.a.',
                id: 'sc.fixed.dealer.financing.paInterestRate.label',
            },
            unit: {
                defaultMessage: '%',
                id: 'sc.fixed.dealer.financing.paInterestRate.unit',
            },
        },
        effinterestrate: {
            label: {
                defaultMessage: 'Effektiver Jahreszins',
                id: 'sc.fixed.dealer.financing.effInterestRate.label',
            },
            unit: {
                defaultMessage: '%',
                id: 'sc.fixed.dealer.financing.effInterestRate.unit',
            },
        },
        rateinsid: {
            label: {
                defaultMessage: 'Kreditschutzversicherung',
                id: 'sc.fixed.dealer.financing.rateInsId.label',
            },
        },
        totalcreditamount: {
            label: {
                defaultMessage: 'Gesamtbetrag',
                id: 'sc.fixed.dealer.financing.totalCreditAmount.label',
            },
            unit: {
                defaultMessage: 'Euro',
                id: 'sc.fixed.dealer.financing.currency.unit',
            },
        },
        rate: {
            rateChangeText: {
                defaultMessage: 'Zeige Leasing Informationen',
                id: 'sc.fixed.dealer.financing.rate.button.change',
            },
            withLeasingRateLabel: {
                defaultMessage: 'Rate ',
                id: 'sc.fixed.dealer.financing.rate.label',
            },
            withoutLeasingRateLabel: {
                defaultMessage: 'oder Rate z.B. mtl. ',
                id: 'sc.details.finance.rate.text1',
            },
            rateLabelConjunction: {
                defaultMessage: 'mit',
                id: 'sc.details.finance.rate.text2',
            },
            unit: {
                defaultMessage: 'Euro',
                id: 'sc.fixed.dealer.financing.currency.unit',
            },
        },
    },
    dealerLeasing: {
        currencyUnit: {
            defaultMessage: '€',
            id: 'sc.fixed.dealer.leasing.currency.unit',
        },
        templates: {
            LS: {
                defaultMessage: '{productName}, Laufzeit {duration} Monate, jährl. Fahrleistung[additionalFootnote] {mileage} km, Sonderzahlung {downPayment}€. {disclaimInfo1}',
                id: '',
            },
        },
        monthlyInstalments: {
            defaultMessage: 'monatliche Leasingraten (inkl. MwSt.) à',
            id: 'sc.fixed.dealer.leasing.monthly.instalments',
        },
        modal: {
            headline: {
                defaultMessage: 'Beispielhafte Leasingkonditionen',
                id: 'sc.fixed.dealer.leasing.headline',
            },
            subHeadline: {
                defaultMessage: 'Dies ist ein Leasingbeispiel. Gerne passt Ihr Audi Partner dieses gemeinsam mit Ihnen an Ihre Bedürfnisse an.',
                id: 'sc.fixed.dealer.leasing.subheadline',
            },
        },
        disclaimers: {
            ls: {
                defaultMessage: 'Ein Angebot der Audi Leasing, Zweigniederlassung der Volkswagen Leasing GmbH, Gifhorner Str. 57, 38112 Braunschweig, für Privatkunden. Bonität vorausgesetzt. Es besteht ein Widerrufsrecht für Verbraucher im Fernabsatz.',
                id: 'sc.fixed.dealer.leasing.leasing.disclaimer',
            },
            vc: {
                defaultMessage: 'KSB Disclaimer',
                id: 'sc.fixed.dealer.leasing.ksb.disclaimer',
            },
        },
        netcreditamount: {
            label: {
                defaultMessage: 'Nettodarlehensbetrag',
                id: 'sc.fixed.dealer.leasing.netCreditAmount.label',
            },
            unit: {
                defaultMessage: '€',
                id: 'sc.fixed.dealer.leasing.currency.unit',
            },
        },
        duration: {
            label: {
                defaultMessage: 'Laufzeit',
                id: 'sc.fixed.dealer.leasing.duration.label',
            },
            unit: {
                defaultMessage: 'Monate',
                id: 'sc.fixed.dealer.leasing.duration.unit',
            },
        },
        mileage: {
            label: {
                defaultMessage: 'Jährliche Fahrleistung',
                id: 'sc.fixed.dealer.leasing.mileage.label',
            },
            unit: {
                defaultMessage: 'km',
                id: 'sc.fixed.dealer.leasing.mileage.unit',
            },
        },
        downpaymentamount: {
            label: {
                defaultMessage: 'Sonderzahlung',
                id: 'sc.fixed.dealer.leasing.downPaymentAmount.label',
            },
            unit: {
                defaultMessage: 'Euro',
                id: 'sc.fixed.dealer.leasing.currency.unit',
            },
        },
        downpayment: {
            label: {
                defaultMessage: 'Anzahlung',
                id: 'sc.fixed.dealer.leasing.downPayment.label',
            },
            unit: {
                defaultMessage: 'Euro',
                id: 'sc.fixed.dealer.leasing.currency.unit',
            },
        },
        finalrate: {
            label: {
                defaultMessage: 'Abschlussrate',
                id: 'sc.fixed.dealer.leasing.finalRate.label',
            },
            unit: {
                defaultMessage: 'Euro',
                id: 'sc.fixed.dealer.leasing.currency.unit',
            },
        },
        painterestrate: {
            label: {
                defaultMessage: 'Sollzinssatz (gebunden) p.a.',
                id: 'sc.fixed.dealer.leasing.paInterestRate.label',
            },
            unit: {
                defaultMessage: '%',
                id: 'sc.fixed.dealer.leasing.paInterestRate.unit',
            },
        },
        effinterestrate: {
            label: {
                defaultMessage: 'Effektiver Jahreszins',
                id: 'sc.fixed.dealer.leasing.effInterestRate.label',
            },
            unit: {
                defaultMessage: '%',
                id: 'sc.fixed.dealer.leasing.effInterestRate.unit',
            },
        },
        rateinsid: {
            label: {
                defaultMessage: 'Kreditschutzversicherung',
                id: 'sc.fixed.dealer.leasing.rateInsId.label',
            },
        },
        totalcreditamount: {
            label: {
                defaultMessage: 'Gesamtbetrag',
                id: 'sc.fixed.dealer.leasing.totalCreditAmount.label',
            },
            unit: {
                defaultMessage: 'Euro',
                id: 'sc.fixed.dealer.leasing.currency.unit',
            },
        },
        rate: {
            rateChangeText: {
                defaultMessage: 'Zeige Leasing Informationen',
                id: 'sc.fixed.dealer.leasing.rate.button.change',
            },
            withLeasingRateLabel: {
                defaultMessage: 'Rate ',
                id: 'sc.fixed.dealer.leasing.rate.label',
            },
            withoutLeasingRateLabel: {
                defaultMessage: 'oder Rate z.B. mtl. ',
                id: 'sc.details.finance.rate.text1',
            },
            rateLabelConjunction: {
                defaultMessage: 'mit',
                id: 'sc.details.finance.rate.text2',
            },
            unit: {
                defaultMessage: 'Euro',
                id: 'sc.fixed.dealer.leasing.currency.unit',
            },
        },
    },
    financeElement: {
        dataLayerHeadline: {
            id: 'sc.details.finance.modal.headline',
            defaultMessage: '',
        },
        rate: {
            beforeLabel: {
                id: 'nemo.ui.sc.details.finance.rate.text1',
                defaultMessage: 'or rate e.g. monthly',
            },
            afterLabel: {
                id: 'nemo.ui.sc.details.finance.rate.text2',
                defaultMessage: 'with',
            },
        },
        disclaimerText: {
            id: 'nemo.ui.sc.details.finance.disclaimer.text',
            defaultMessage: 'Ex.:',
        },
        regularPriceLabel: {
            usedCar: {
                id: 'nemo.ui.sc.uc.tiles.regularprice',
                defaultMessage: '',
            },
            newCar: {
                id: 'nemo.ui.sc.tiles.regularprice',
                defaultMessage: '',
            },
        },
        retailPriceLabel: {
            usedCar: {
                id: 'nemo.ui.sc.uc.tiles.retailprice',
                defaultMessage: '',
            },
            newCar: {
                id: 'nemo.ui.sc.tiles.retailprice',
                defaultMessage: '',
            },
        },
        additionalPriceLabel: {
            usedCar: {
                id: 'nemo.ui.sc.finance.additional.text.usedcar',
                defaultMessage: '',
            },
            newCar: {
                id: 'nemo.ui.sc.finance.additional.text.newcar',
                defaultMessage: '',
            },
        },
        vatReclaimable: {
            id: 'nemo.ui.sc.vat.reclaimable',
            defaultMessage: 'VAT reclaimable',
        },
        vatNotReclaimable: {
            id: 'nemo.ui.sc.vat.not-reclaimable',
            defaultMessage: 'VAT Not reclaimable',
        },
    },
    expandable: {
        button: {
            lessLabel: {
                id: 'nemo.ui.sc.expandable.button.label.less',
                defaultMessage: 'show less information',
            },
            moreLabel: {
                id: 'nemo.ui.sc.expandable.button.label.more',
                defaultMessage: 'show more information',
            },
        },
    },
    consumptionCombinedLabel: {
        id: 'nemo.ui.sc.consumption.combined.label',
        defaultMessage: 'combined',
    },
    emissionCombinedLabel: {
        id: 'nemo.ui.sc.emission.combined.label',
        defaultMessage: 'combined',
    },
    prices: {
        regular: {
            defaultMessage: '',
            id: 'nemo.ui.sc.result.regularprice',
        },
        regularPricePrefix: {
            defaultMessage: '',
            id: 'nemo.ui.sc.tiles.regularprice.prefix',
        },
        options: {
            defaultMessage: 'Options price',
            id: 'nemo.ui.sc.result.optionsprice',
        },
        retail: {
            defaultMessage: '',
            id: 'nemo.ui.sc.result.actionprice',
        },
        allIn: {
            defaultMessage: 'Total price',
            id: 'nemo.ui.sc.result.allinprice',
        },
        allInTooltipInfo: {
            defaultMessage: '',
            id: 'nemo.ui.sc.result.allinprice.tooltip.info',
        },
        allInFallback: {
            defaultMessage: 'please enquire',
            id: 'nemo.ui.sc.result.allinprice.fallback',
        },
        expenses: {
            defaultMessage: 'Expenses  ',
            id: 'nemo.ui.sc.result.expenses',
        },
        expensesTooltipInfo: {
            defaultMessage: '',
            id: 'nemo.ui.sc.result.expenses.tooltip.info',
        },
        expensesFallback: {
            defaultMessage: 'please enquire',
            id: 'nemo.ui.sc.result.expenses.fallback',
        },
        environmentTax: {
            prefix: {
                defaultMessage: '',
                id: 'nemo.ui.sc.prices.environmenttax.prefix',
            },
            suffix: {
                defaultMessage: '',
                id: 'nemo.ui.sc.prices.environmenttax.suffix',
            },
        },
        financeRate: {
            prefix: {
                defaultMessage: '',
                id: 'nemo.ui.sc.prices.financerate.prefix',
            },
            suffix: {
                defaultMessage: '',
                id: 'nemo.ui.sc.prices.financerate.suffix',
            },
        },
        downPayment: {
            prefix: {
                defaultMessage: '',
                id: 'nemo.ui.sc.prices.downpayment.prefix',
            },
            suffix: {
                defaultMessage: '',
                id: 'nemo.ui.sc.prices.downpayment.suffix',
            },
        },
        text: {
            defaultMessage: '',
            id: 'nemo.ui.sc.details.price.text',
        },
        footnote: {
            uc: {
                defaultMessage: '',
                id: 'nemo.ui.sc.uc.details.footnote.price',
            },
            ucRetail: {
                defaultMessage: '',
                id: 'nemo.ui.sc.uc.details.footnote.actionprice',
            },
            nc: {
                defaultMessage: '',
                id: 'nemo.ui.sc.details.footnote.price',
            },
            ncRetail: {
                defaultMessage: '',
                id: 'nemo.ui.sc.details.footnote.actionprice',
            },
        },
        finance: {
            uc: {
                defaultMessage: '',
                id: 'nemo.ui.sc.finance.additional.text.usedcar',
            },
            nc: {
                defaultMessage: '',
                id: 'nemo.ui.sc.finance.additional.text.newcar',
            },
            exceptional: {
                monthlyRate: {
                    defaultMessage: 'Monthly Rate',
                    id: 'nemo.ui.sc.finance.exceptional.monthly_rate',
                },
            },
        },
        basePriceLabel: {
            defaultMessage: '',
            id: 'nemo.ui.sc.price.base.label',
        },
        combinedTaxesLabel: {
            defaultMessage: '',
            id: 'nemo.ui.sc.price.combined.tax.label',
        },
        vatReclaimable: {
            defaultMessage: '',
            id: 'nemo.ui.sc.vat.reclaimable',
        },
        vatNotReclaimable: {
            id: 'nemo.ui.sc.vat.not-reclaimable',
            defaultMessage: '',
        },
        taxationInformation: {
            id: 'nemo.ui.sc.result.taxation',
            defaultMessage: 'Taxation',
        },
        nws: {
            defaultMessage: 'nws info',
            id: 'nemo.ui.sc.finance.nws.info',
        },
    },
    leasing: {
        leasingLabel: {
            defaultMessage: 'Leasing',
            id: 'nemo.ui.sc.details.leasing.label',
        },
        leasingInfoText: {
            defaultMessage: 'leasing info text',
            id: 'nemo.ui.sc.details.leasing.button.info.text',
        },
        leasingBuyableOnlineLabel: {
            defaultMessage: 'Leasing Buyable Online',
            id: 'nemo.ui.sc.details.leasing.buyable-online',
        },
        leasingDisclaimerText: {
            defaultMessage: '',
            id: 'nemo.ui.sc.details.leasing.disclaimer.text',
        },
        financeRateButtonChange: {
            defaultMessage: '',
            id: 'nemo.ui.sc.details.finance.rate.button.change',
        },
    },
    fuelType: {
        biomethanLabel: {
            id: 'nemo.ui.sc.tiles.fuel.type.biomathane.label',
            defaultMessage: 'Biomethan',
        },
        cngLabel: {
            id: 'nemo.ui.sc.tiles.fuel.type.cng.label',
            defaultMessage: 'CNG',
        },
        compressedAirLabel: {
            id: 'nemo.ui.sc.tiles.fuel.type.compressed_air.label',
            defaultMessage: 'Compressed air',
        },
        dieselLabel: {
            id: 'nemo.ui.sc.tiles.fuel.type.diesel.label',
            defaultMessage: 'Diesel',
        },
        electricalLabel: {
            id: 'nemo.ui.sc.tiles.fuel.type.electrical.label',
            defaultMessage: 'Electrical',
        },
        ethanolLabel: {
            id: 'nemo.ui.sc.tiles.fuel.type.ethanol.label',
            defaultMessage: 'Ethanol',
        },
        h2ngLabel: {
            id: 'nemo.ui.sc.tiles.fuel.type.h2ng.label',
            defaultMessage: 'H2NG',
        },
        he15Label: {
            id: 'nemo.ui.sc.tiles.fuel.type.he_15.label',
            defaultMessage: 'HE_15',
        },
        hydrogenLabel: {
            id: 'nemo.ui.sc.tiles.fuel.type.hydrogen.label',
            defaultMessage: 'Hydrogen',
        },
        lngLabel: {
            id: 'nemo.ui.sc.tiles.fuel.type.lng.label',
            defaultMessage: 'LNG',
        },
        lpgLabel: {
            id: 'nemo.ui.sc.tiles.fuel.type.lpg.label',
            defaultMessage: 'LPG',
        },
        mixtureLabel: {
            id: 'nemo.ui.sc.tiles.fuel.type.mixture.label',
            defaultMessage: 'Mix',
        },
        otherLabel: {
            id: 'nemo.ui.sc.tiles.fuel.type.other.label',
            defaultMessage: 'Other',
        },
        petrolLabel: {
            id: 'nemo.ui.sc.tiles.fuel.type.petrol.label',
            defaultMessage: 'Petrol',
        },
    },
    availableNowLabel: {
        id: 'nemo.ui.sc.highlights.availablefromdate.available.now.label',
        defaultMessage: 'Verfügbar',
    },
    availableLaterLabel: {
        id: 'nemo.ui.sc.highlights.availablefromdate.available.later.label',
        defaultMessage: 'Voraussichtlich verfügbar ab',
    },
    availableNowDescription: {
        id: 'nemo.ui.sc.highlights.availablefromdate.available.now.description',
        defaultMessage: 'now',
    },
    vehicleInspectionDueDatePastDescription: {
        id: 'nemo.ui.sc.highlights.vehicleinspectionduedate.past.description',
        defaultMessage: 'Vehicle inspection 2 years included',
    },
    drivingCycles: {
        wltp: {
            label: { id: 'nemo.ui.sc.wltp.item.label', defaultMessage: 'WLTP' },
        },
        nedc: {
            label: { id: 'nemo.ui.sc.nedc.item.label', defaultMessage: 'NEDC' },
        },
    },
    availabeSoon: {
        expected: {
            defaultMessage: '',
            id: 'nemo.ui.sc.tiles.expected.to.be',
        },
        expectedPriceLabel: {
            defaultMessage: '',
            id: 'nemo.ui.sc.tiles.expected.pricelabel',
        },
        availability: {
            defaultMessage: '',
            id: 'nemo.ui.sc.tiles.expected.availability',
        },
        expectedAbbreviation: {
            defaultMessage: '',
            id: 'nemo.ui.sc.tiles.available.from.abbreviation',
        },
        fromDate: {
            defaultMessage: '',
            id: 'nemo.ui.sc.tiles.available.from',
        },
        fromDateFallback: {
            id: 'nemo.ui.sc.available.soon.fallback.text',
            defaultMessage: 'Soon (fallback)',
        },
        expectedMileage: {
            defaultMessage: 'Expected Mileage',
            id: 'nemo.ui.sc.available.soon.mileage',
        },
    },
    suggested: {
        headline: {
            defaultMessage: 'Empfohlene Fahrzeuge',
            id: 'nemo.ui.sc.tiles.suggested.headline',
        },
        similarVehicles: {
            defaultMessage: 'Ähnliche Fahrzeuge',
            id: 'nemo.ui.sc.tiles.suggested.similarVehicles',
        },
        lastVisitedVehicles: {
            defaultMessage: 'Zuletzt angesehene Fahrzeuge',
            id: 'nemo.ui.sc.tiles.suggested.lastVisitedVehicles',
        },
        similarVehiclesFromDealer: {
            defaultMessage: 'Ähnliche Fahrzeuge des Händlers',
            id: 'nemo.ui.sc.tiles.suggested.similarVehiclesFromDealer',
        },
    },
    dealerChain: {
        headline: {
            defaultMessage: 'Investor shared vehicle',
            id: 'sc.fa-dealer-chain.headline',
        },
    },
    vehiclePreuseDescription: {
        'demonstration-car': {
            id: 'nemo.ui.sc.vehicle-preuse.demonstration-car',
            defaultMessage: 'Demonstration car',
        },
        'one-year-car': {
            id: 'nemo.ui.sc.vehicle-preuse.one-year-car',
            defaultMessage: 'One year car',
        },
        'company-car': { id: 'nemo.ui.sc.vehicle-preuse.company-car', defaultMessage: 'Company car' },
        'used-car': { id: 'nemo.ui.sc.vehicle-preuse.used-car', defaultMessage: 'Used car' },
        none: { id: '', defaultMessage: '' },
    },
    availableSoonMileage: { id: 'nemo.ui.sc.available.now.mileage', defaultMessage: '' },
    usedCarMileage: {
        id: 'nemo.ui.sc.available.soon.mileage',
        defaultMessage: 'voraussichtliche Kilometer',
    },
    availableSoon: { id: 'nemo.ui.sc.available.soon.fallback.text', defaultMessage: 'Soon' },
};

import styled from 'styled-components';
import { Text, TextProps, Popover, PopoverProps } from '@audi/audi-ui-react-v2';
import React from 'react';

export const ResultsBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: var(${(props): string => props.theme.responsive.spacing.s});
`;

export const ViewTypeContainer = styled.div`
  display: none;

  @media screen and (min-width: 768px) {
    display: flex;
    align-self: end;
  }
  @media screen and (min-width: 1024px) {
    & button:first-child {
      margin-right: var(${(props): string => props.theme.responsive.spacing.s});
    }
  }
`;

export const StyledButton = styled.button<{ active: boolean }>`
  border: 0;
  background: none;
  margin-left: var(${(props): string => props.theme.responsive.spacing.xl});
  display: flex;
  align-items: center;
  cursor: pointer;

  color: var(
    ${(props): string =>
      props.active ? props.theme.colors.base.brand.black : props.theme.colors.base.grey[70]}
  );
`;

export const StyledButtonText = styled((props) => <Text as="span" {...props} />)<TextProps>`
  display: none;

  @media screen and (min-width: 1024px) {
    display: block;
  }
`;

export const CountAndSortContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CountAndShareContainer = styled.div`
  display: inline-flex;
`;

export const SortContainer = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const StyledInfoText = styled((props) => <Popover {...props} />)<PopoverProps>`
  width: 315px;
  left: 4px !important;
  background-color: #ffffff;
  font-family: var(--typography-copy1-fontFamily-585fc86);
  font-size: var(--typography-copy1-fontSize-585fc86);
  font-weight: var(--fontWeights-normal-585fc86);
  color: var(--colors-ui-secondary-585fc86);
`;
